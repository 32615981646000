import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styled from 'styled-components'
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import ArrowRightKey from "../icons/arrow-right-key"
import Building from "../icons/building"
import { addCommas } from "../../utils/str"

const styles = theme => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },

  rootBg: {
    padding: "22px 24px",
    height: "94px",
  },

  rootSm: {
    padding: "8px 10px",
    height: "64px",
  },

  icon: {
    minWidth: "48px",
    width: "48px",
    height: "48px",
    marginRight: "14px",
    background: "#040E35",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "24px",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width: "calc(100% - 62px)",
  },

  header: {
    fontWeight: "bold",
    color: "#181819",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },

  headerBg: {
    fontSize: "16px",
    marginBottom: "7px",
  },

  headerSm: {
    fontSize: "13px",
    marginBottom: "5px",
  },

  desc: {
    color: "#71737B",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },

  descBg: {
    fontSize: "12px",
  },

  descSm: {
    fontSize: "11px",
  },

  headerBlock: {
    width: "35%",
    height: "16px",
    background: "#D8DBDF",
    marginBottom: "8px",
    borderRadius: "2px",
  },

  descBlock: {
    width: "90%",
    height: "12px",
    background: "#D8DBDF",
    borderRadius: "2px",
  },

  nameBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    overflow: "hidden",
  },

  name: {
    minWidth: "50%",
    display: "flex",
    flex: 1,
  },
})

const PositionItem = ({
  classes,
  item,
  small,
  showBorderBottom,
}) => {
  let size = small ? "Sm" : "Bg"
  const corporation_val = (typeof window !== 'undefined' ? window.location.href.match("corporations/(.*)/positions") : '');

  if (!item) {
    return (
      <Link style={{ pointerEvents: "none" }}>
        <Root className={classNames(classes.root, classes[`root${size}`])} style={{ borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }} >
          <div className={classes.icon}>
            <Building width={26} height={26} />
          </div>
          <div className={classes.container}>
            <div className={classes.nameBlock}>
              <NameContainer className={classes.name}>
                <div className={classes.headerBlock}></div>
              </NameContainer>
            </div>
            <div className={classNames(classes.desc, classes[`desc${size}`])}>
              <div className={classes.descBlock}></div>
            </div>
          </div>
        </Root>
      </Link>
    )
  } else {
    let path = `/positions/${item.value}`;
    if (corporation_val) {
      path = `corporations/${decodeURI(corporation_val[1])}/positions/${item.value}`
    }
    return (
      <Link to={path}>
      <Root className={classNames(classes.root, classes[`root${size}`])} style={{ borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }} >
        <div className={classes.icon}>
          <Building width={26} height={26} />
        </div>
        <div className={classes.container}>
          <div className={classes.nameBlock}>
            <NameContainer className={classes.name}>
              <span className={classNames(classes.header, classes[`header${size}`])}>
                {item.name}
              </span>
            </NameContainer>
          </div>
          <div className={classNames(classes.desc, classes[`desc${size}`])}>
            {item.hasOwnProperty("count") && (
              <div className={classes.desc}>
                {addCommas(item.count)}人の{item.name}
              </div>
            )}
          </div>
        </div>
        <ArrowContainer>
          <ArrowRightKey
            height={24}
            width={24}
            color="#1866FF"
          />
        </ArrowContainer>
      </Root>
    </Link>
    )
  }
}

const Root = styled.div`
  &:hover {
    cursor: pointer;
    background: #EFF7FF;
  }
`;

const ArrowContainer = styled.div`
  & {
    display: none;
  }
  ${Root}:hover & {
    display: block;
  }
`;

const NameContainer = styled.div`
  & {
    text-decoration: unset;
    color: #000000;
  }
  ${Root}:hover & {
    text-decoration: underline;
  }
`;

PositionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
}

PositionItem.defaultProps = {
  showBorderBottom: true,
}

export default withStyles(styles)(PositionItem)
