import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import { POSITIONS_PER_PAGE } from "../constants/constants"
import ListLayout from "../components/common/list-layout"
import PositionItem from "../components/common/position-item"
import { Location } from "@reach/router"
import { getQueryVariable } from "../utils/str"

const mapStateToProps = (state, ownProps) => {
  return {
    positions: state.positions.positions.toIndexedSeq().toArray(),
    pageInfo: state.positions.pageInfo_position,
    isLoading: state.positions.isLoadingPosition,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    corporation: state.corporation.corporation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    countForCorporationCustomPages: data => dispatch(actions.countForCorporationCustomPages(data)),
    getKeymansPositions: data => dispatch(actions.getKeymansPositions(data)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getKeymansCount: () => dispatch(actions.getKeymansCount()),
    getCorporationsById: id => dispatch(actions.getCorporationsById(id)),
  }
}

const styles = theme => ({})

class Positions extends React.Component {
  componentDidMount() {
    const { path, location } = this.props
    const corporation_val = path ? path.match("corporations/(.*)/positions") : ''
    const page_val = location && location.search ? location.search.match("page=(.*)"): ''

    // 企業データと役職のかけあわせページの場合(/corporations/{id}/positions)
    if (corporation_val) {
      this.props.getCorporationsById(decodeURI(corporation_val[1]))
      this.props.countForCorporationCustomPages({
        corporation_id: [decodeURI(corporation_val[1])],
        page_info: 'positions',
        page: page_val ? parseInt(page_val[1]) : 1})
    } else {
      this.props.getKeymansCount()
    }
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  getQuery = (props, query = {}) => {
    const corporation_val = props && props.path ? props.path.match("corporations/(.*)/positions") : ''
    return {
      paging: 1,
      per_page: POSITIONS_PER_PAGE,
      page: 1,
      corporation_id: corporation_val ? [decodeURI(corporation_val[1])] : '',
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      positions,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      corporation,
      updateKeymans,
      isLoadingUpdateKeymans,
      path,
    } = this.props
    const corporation_val = path ? path.match("corporations/(.*)/positions"): ''
    let corporationName = corporation ? corporation.company_name : '企業'

    return (
      <ListLayout
        name={`${corporationName}の役職`}
        title={`${corporationName}の役職一覧`}
        ItemComponent={PositionItem}
        items={positions}
        isLoading={isLoading}
        perPage={POSITIONS_PER_PAGE}
        pageInfo={pageInfo}
        getItems={(data, overwrite) =>
          this.props.getKeymansPositions(
            this.getQuery(this.props, data),
            overwrite
          )
        }
        corporationName={!!corporation_val ? corporationName : ''}
        updateKeymans={updateKeymans}
        isLoadingUpdateKeymans={isLoadingUpdateKeymans}
        keymanCountYstd={keymanCountYstd}
        keymansTotal={keymansTotal}
        corporationsTotal={corporationsTotal}
        path={path ? path.slice(1) : ''}
        isInitialized={true}
      />
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Positions {...locationProps} {...props} />}
    </Location>
  ))
)
